import React from "react"
import PageTemplate from '../components/pageTemplate'
import OrderForm from '../components/orderForm'

export default () => (
  <PageTemplate title="Make an Order">
    <div className="text-center mb-4">
      Distributors Only. For first time orders please contact us to setup your account.
    </div>
    <OrderForm/>
  </PageTemplate>
)

import React from 'react'
import {NormalField} from 'reactstrap-form-fields'
import { Button, Col, Row } from 'reactstrap'

let yoghurts = [
  "300G PASSIONFRUIT",
  "300G MIXEDBERRY",
  "300G MANGO",
  "300G NATURAL (SWEETENED)",
  "300G APPLE CINNAMON",
  "300G RHUBARB STRAWBERRY",
  "300G HONEY",
  "300G BLUEBERRY",
  "300G LEMON MYRTLE",
  "160G SPLIT CUP MUESLI",
  "300G STRAWBERRY SUNDAE",
  "300G BANANA CARAMEL",
  "10KG NATURAL SWEETENED",
  "10KG NATURAL UNSWEETENED",
  "COCONUT YOGHURT 400G",
  "1KG PASSIONFRUIT",
  "1KG MIXEDBERRY",
  "1KG MANGO",
  "1KG NATURAL (SWEETENED)",
  "1KG APPLE CINNAMON",
  "1KG RHUBARB STRAWBERRY",
  "1KG BLUEBERRY",
  "1 KG POT SET (NATURAL UNSWEETEND)"
]

let items = [
  "RICE PUDDING 350G  (6 PER CTN)",
  "CHOCOLATE MOOSE 110G (6 PER CTN)",
  "300G CRUSHED GARLIC",
  "300G CRUSHED GINGER",
  "300G CHRUSHED CHILLI",
  "1KG CRUSHED GARLIC",
  "1KG CRUSHED GINGER",
  "RICOTTA 1KG",
  "FETA CHEESE BLOCK  1KG",
  "FETA CHEESE BLOCK  2KG",
  "DICED FETA  2KG",
  "DANNISH FETA  2KG",
  "HALLOUMI CHEESE  225G",
  "TRADITIONAL FETA 200G (6 PER CTN)",
  "BELLA  SHAVED PARMESAN  250G",
  "BELLA TASTY SHED  500G",
  "BELLAS TASTY SLICES  250G",
  "BELLA MOZZARELLA  500G",
  "BELLA TASTY NATURAL BLOCK  500G",
  "SHREDD PLUS  2KG",
  "SHREDD MOZZARELLA  2KG",
  "SHAVED PARMESAN  1KG",
]

let antipasto = [
  "SUNDRIED KALAMATA  OLIVES",
  "GREEN OLIVES LEMON GARLIC",
  "GREEN OLIVES FETTA",
  "MINI BELL PEPPERS FETTA",
  "PERSIAN FETTA -BASIL",
  "PERSIAN FETTA CLASSIC",
  "GREEK SALAD MIX",
  "SEMI DRIED TOMATOS",
  "PERSIAN FETTA AND SUNDRIED TOM",
  "MIXED PITTED MARINATED OLIVES",
  "SICILLIAN GREEN OLIVES"
]

let dips = [
  "BASIL, FETA AND SEMI DRIED TOMATO",
  "BASIL PESTO",
  "SPINACH AND FETA",
  "BEETROOT AND FETA",
  "SPRING ONION",
  "FRENCH ONION",
  "HOMMUS",
  "RED PESTO",
  "BRUSHETTA",
  "SPICY CORRIANDER",
  "OLIVE TAPENADE",
  "PUMPKIN PESTO",
  "TZAZIKI",
  "TARAMASALATA",
  "BABA GANOUSH",
  "CAPSICUM & ALMOND"
]

let TableRow = ({item, onChange}) => (
  <Col md="6" className="mb-2">
    <Row>
      <Col><span>{item}</span></Col>
      <Col>
        <input placeholder="Quantity" item={item} className="form-control" type="number" onChange={onChange}/>
      </Col>
    </Row>
  </Col>
)

let TableRows = ({items, title, onChange}) => (
  <>
    <h4 className="mb-4">{title}</h4>
    <Row>
      {items.map((item, i) => (
        <TableRow item={item} key={i} onChange={onChange}/>
      ))}
    </Row>
    <hr/>
  </>
)

export default class IndexPage extends React.Component {
  state = {
    order: {},
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.getAttribute('item')
    console.log(target)
    this.setState({
      order: {
        ...this.state.order,
        [name]: value
      },
    })
  }

  render () {
    let order_items = Object.keys(this.state.order).map( i => (
      `${i}: ${this.state.order[i]}`
    )).join(', ')
    return (
      <form name="Order Form" netlify-honeypot="bot-field" data-netlify="true">
        <input type="hidden" name="bot-field" />
        <NormalField name="Business Name"/>
        <NormalField name="Order items" value={order_items} type="hidden" label={false}/>
        <TableRows items={items} title="Choose your items" onChange={this.handleInputChange}/>
        <TableRows items={yoghurts} title="Yoghurts" onChange={this.handleInputChange}/>
        <TableRows items={antipasto} title="Antipasto 250g" onChange={this.handleInputChange}/>
        <TableRows items={dips} title="Dips 180g" onChange={this.handleInputChange}/>
        <NormalField name="Comments" type="textarea" required={false}/>
        <Button className="btn-block">Submit</Button>
      </form>
    )
  }
}
